import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik"
import React from "react"
import {
  getInputStyle,
  StyledEmailInput,
  StyledLabel,
  StyledNameInput,
  StyledNumberInput,
  StyledSelect,
  StyledSubmitButton,
  StyledSubmitButtonAlt,
  StyledTextInput,
  encode,
  getErrorStyle,
} from "../../assets/form-helper"
import * as Yup from "yup"
import { SectionHeader } from "../typography/section-headers"
import { PageH5Header } from "../typography/page-headers"

const inputStyle = getInputStyle()

const childNum = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export default function RegistrationForm2() {
  const initialValues = {
    parentFirstName: "",
    parentLastName: "",
    parentEmail: "",
    firstChildName: "",
    firstChildAge: "",
    secondChildName: "",
    secondChildAge: "",
    thirdChildName: "",
    thirdChildAge: "",
    fourthChildName: "",
    fourthChildAge: "",
  }

  function onSubmit(fields, actions) {
    // display form field values on success
    // alert("SUCCESS!! :-)\n\n" + JSON.stringify(fields, null, 4))
    // console.log( actions)
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "event-register", ...fields }),
    })
      .then(() => {
        alert("Got it! Thanks!")
        actions.resetForm()
        // console.log(values)
      })
      .catch(() => {
        alert("Error")
      })
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ errors, values, touched, setValues }) => (
        <Form name="event-register" data-netlify="true">
          <input type="hidden" name="form-name" value="Event-Register" />

          {/* <!--Parent Name--> */}
          <StyledNameInput
            label={"Enter your / parent name"}
            name="Name"
            required={true}
            id="Name"
            preId={"parent"}
            tint={"dark"}
          />

          {/* <!--Parent Email--> */}
          <StyledEmailInput
            name="parentEmail"
            id="parentEmail"
            label={"Enter your / parent email address"}
            tint={"dark"}
          />

          {/* <!--Child One--> */}
          <div className="block py-4 sm:p-2 pb-4 border-b-1 border-slate-200">
            <PageH5Header text={"First Child"} tint={"light"} />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="block">
                <StyledLabel
                  label={"First Child Name"}
                  labelFor={`firstChildName`}
                  required={true}
                  tint={"dark"}
                />
                <Field
                  name={`firstChildName`}
                  type="text"
                  className={inputStyle}
                />
              </div>

              <div className="block">
                <StyledLabel
                  label={"Child Age"}
                  labelFor={`firstChildAge`}
                  required={true}
                  tint={"dark"}
                />
                <Field
                  name={`firstChildAge`}
                  type="number"
                  className={inputStyle}
                />
              </div>
            </div>
          </div>

          {/* <!--Child Two--> */}
          <div className="block py-4 sm:p-2 pb-4 border-b-1 border-slate-200">
            <PageH5Header text={"Second Child"} tint={"light"} />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="block">
                <StyledLabel
                  label={"Second Child Name"}
                  labelFor={`secondChildName`}
                  tint={"dark"}
                />
                <Field
                  name={`secondChildName`}
                  type="text"
                  className={inputStyle}
                />
              </div>

              <div className="block">
                <StyledLabel
                  label={"Child Age"}
                  labelFor={`secondChildAge`}
                  tint={"dark"}
                />
                <Field
                  name={`secondChildAge`}
                  type="number"
                  className={inputStyle}
                />
              </div>
            </div>
          </div>

          {/* <!--Child Three--> */}
          <div className="block py-4 sm:p-2 pb-4 border-b-1 border-slate-200">
            <PageH5Header text={"Third Child"} tint={"light"} />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="block">
                <StyledLabel
                  label={"Third Child Name"}
                  labelFor={`thirdChildName`}
                  tint={"dark"}
                />
                <Field
                  name={`thirdChildName`}
                  type="text"
                  className={inputStyle}
                />
              </div>

              <div className="block">
                <StyledLabel
                  label={"Child Age"}
                  labelFor={`thirdChildAge`}
                  required={true}
                  tint={"dark"}
                />
                <Field
                  name={`thirdChildAge`}
                  type="number"
                  className={inputStyle}
                />
              </div>
            </div>
          </div>

          {/* <!--Child Four--> */}
          <div className="block py-4 sm:p-2 pb-4 border-b-1 border-slate-200">
            <PageH5Header text={"Fourth Child"} tint={"light"} />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="block">
                <StyledLabel
                  label={"Fourth Child Name"}
                  labelFor={`fourthChildName`}
                  required={true}
                  tint={"dark"}
                />
                <Field
                  name={`fourthChildName`}
                  type="text"
                  className={inputStyle}
                />
              </div>

              <div className="block">
                <StyledLabel
                  label={"Child Age"}
                  labelFor={`fourthChildAge`}
                  required={true}
                  tint={"dark"}
                />
                <Field
                  name={`fourthChildAge`}
                  type="number"
                  className={inputStyle}
                />
              </div>
            </div>
          </div>

          {/* <!--Submit--> */}
          <StyledSubmitButtonAlt text={"Submit"} />
        </Form>
      )}
    </Formik>
  )
}
