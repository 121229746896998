/* This example requires Tailwind CSS v2.0+ */
import React from "react"
import CustomInteriorPage from "../components/page-layouts/custom-interior-page"
import Oct15Form from "../components/forms/oct-15-form"
import {
  PageH1Header,
  PageH2Header,
} from "../components/typography/page-headers"
import { StaticImage } from "gatsby-plugin-image"
import RegistrationForm2 from "../components/forms/registration-form-2"

function PageData() {
  return (
    <div>
      <div className="relative bg-coffee z-20 overflow-hidden">
        <div className="grid grid-cols-1 md:grid-cols-2 relative">
          <div className="relative">
            <div className="relative max-w-4xl mx-auto pt-12 pb-6 md:py-32 px-8 z-30">
              <PageH1Header text="Event Registration" tint={"light"} />
              <div className="md:w-2/3">
                <p className="text-off-white font-bold text-xl">
                  Please fill out the form below to register for our event. We
                  will reach out to you later to speak to you about Scouts.
                </p>
              </div>
            </div>
            <div className="absolute inset-0 opacity-20 z-10">
              <StaticImage
                placeholder="dominantColor"
                src="../images/pottstown-riverfront-park.jpg"
                alt="Collage of different projects"
                layout="fixed"
                objectFit="contain"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />
            </div>
          </div>
          <div className="relative z-30">
            <div className="relative max-w-4xl mx-auto p-6 pt-0 md:py-32 px-8 z-30">
              <RegistrationForm2 />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function RegistrationPage() {
  const siteTitle = "Event Registration"

  return <CustomInteriorPage body={PageData()} seo={siteTitle} />
}
